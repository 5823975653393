.button-container {

    height: 100%;
}
  
.glass-btn {

  align-items: center;
  justify-content: center;
  border-radius: 7px;
  margin-left: 1em;
  margin-right: 1em;  
  cursor: pointer;
  transition: all 0.3s;

  
}

  .blue-btn {
    -webkit-box-shadow: 0px 20px 20px -17px rgba(0, 111, 255, 0.53);
    -moz-box-shadow: 0px 20px 20px -17px rgba(0, 111, 255, 0.53);
    box-shadow: 0px 20px 20px -17px rgba(0, 111, 255, 0.53);

  }



  .blue-btn > content {
    font-size: 4em;
    font-family: "Roboto", sans-serif;
    background: linear-gradient(to right, #006fff, #00acff 70%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    text-shadow: 0px 10px 37px rgba(0, 111, 255, 0.8);
  }


  .main-div {
    position: relative;
    margin: 10px;
    background-color: transparent;
  
  }
  
  .main-div1::before {
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    width: 50%;
    height: 50%;
    -webkit-box-shadow: 0 0 17px 3px #ffff01,0 0 4px 2px #ffff01;
            box-shadow: 0 0 17px 3px #ffff01,0 0 4px 2px #ffff01;
    z-index: -1;
    -webkit-animation-name: yellow-shadow;
            animation-name: yellow-shadow;
    -webkit-animation-timing-function: ease;
            animation-timing-function: ease;
    -webkit-animation-duration: 2s;
            animation-duration: 2s;
    -webkit-animation-iteration-count: infinite;
            animation-iteration-count: infinite;
    border-radius: 5px;
    -webkit-border-radius: 5px;
    -moz-border-radius: 5px;
    -ms-border-radius: 5px;
    -o-border-radius: 5px;
  }
  
  .main-div1::after {
    content: '';
    position: absolute;
    right: 0;
    bottom: 0;
    width: 50%;
    height: 50%;
    -webkit-box-shadow: 0 0 17px 3px #0ff,0 0 4px 2px #0ff;
            box-shadow: 0 0 17px 3px #0ff,0 0 4px 2px #0ff;
    z-index: -1;
    -webkit-animation-name: cyan-shadow;
            animation-name: cyan-shadow;
    -webkit-animation-timing-function: ease;
            animation-timing-function: ease;
    -webkit-animation-duration: 2s;
            animation-duration: 2s;
    -webkit-animation-iteration-count: infinite;
            animation-iteration-count: infinite;
    border-radius: 5px;
    -webkit-border-radius: 5px;
    -moz-border-radius: 5px;
    -ms-border-radius: 5px;
    -o-border-radius: 5px;
  }

  @-webkit-keyframes yellow-shadow {
    0% {
      top: 0;
      left: 0;
    }
    25% {
      top: 50%;
      left: 0;
    }
    50% {
      top: 50%;
      left: 50%;
    }
    75% {
      top: 0;
      left: 50%;
    }
    100% {
      top: 0;
      left: 0;
    }
  }
  
  @keyframes yellow-shadow {
    0% {
      top: 0;
      left: 0;
    }
    25% {
      top: 50%;
      left: 0;
    }
    50% {
      top: 50%;
      left: 50%;
    }
    75% {
      top: 0;
      left: 50%;
    }
    100% {
      top: 0;
      left: 0;
    }
  }
  
  @-webkit-keyframes cyan-shadow {
    0% {
      right: 0;
      bottom: 0;
    }
    25% {
      right: 0;
      bottom: 50%;
    }
    50% {
      right: 50%;
      bottom: 50%;
    }
    75% {
      right: 50%;
      bottom: 0;
    }
    100% {
      right: 0;
      bottom: 0;
    }
  }
  
  @keyframes cyan-shadow {
    0% {
      right: 0;
      bottom: 0;
    }
    25% {
      right: 0;
      bottom: 50%;
    }
    50% {
      right: 50%;
      bottom: 50%;
    }
    75% {
      right: 50%;
      bottom: 0;
    }
    100% {
      right: 0;
      bottom: 0;
    }
  }
  
  @-webkit-keyframes gradient-shadow {
    0% {
      -webkit-box-shadow: 0 0 17px 3px #C586C0,0 0 4px 2px #C586C0;
              box-shadow: 0 0 17px 3px #C586C0,0 0 4px 2px #C586C0;
    }
    20% {
      -webkit-box-shadow: 0 0 17px 3px #0ff,0 0 4px 2px #0ff;
              box-shadow: 0 0 17px 3px #0ff,0 0 4px 2px #0ff;
    }
    40% {
      -webkit-box-shadow: 0 0 17px 3px #0f0,0 0 4px 2px #0f0;
              box-shadow: 0 0 17px 3px #0f0,0 0 4px 2px #0f0;
    }
    60% {
      -webkit-box-shadow: 0 0 17px 3px #ffff01,0 0 4px 2px #ffff01;
              box-shadow: 0 0 17px 3px #ffff01,0 0 4px 2px #ffff01;
    }
    80% {
      -webkit-box-shadow: 0 0 17px 3px #f00,0 0 4px 2px #f00;
              box-shadow: 0 0 17px 3px #f00,0 0 4px 2px #f00;
    }
    100% {
      -webkit-box-shadow: 0 0 17px 3px #C586C0,0 0 4px 2px #C586C0;
              box-shadow: 0 0 17px 3px #C586C0,0 0 4px 2px #C586C0;
    }
  }
  
  @keyframes gradient-shadow {
    0% {
      -webkit-box-shadow: 0 0 17px 3px #C586C0,0 0 4px 2px #C586C0;
              box-shadow: 0 0 17px 3px #C586C0,0 0 4px 2px #C586C0;
    }
    20% {
      -webkit-box-shadow: 0 0 17px 3px #0ff,0 0 4px 2px #0ff;
              box-shadow: 0 0 17px 3px #0ff,0 0 4px 2px #0ff;
    }
    40% {
      -webkit-box-shadow: 0 0 17px 3px #0f0,0 0 4px 2px #0f0;
              box-shadow: 0 0 17px 3px #0f0,0 0 4px 2px #0f0;
    }
    60% {
      -webkit-box-shadow: 0 0 17px 3px #ffff01,0 0 4px 2px #ffff01;
              box-shadow: 0 0 17px 3px #ffff01,0 0 4px 2px #ffff01;
    }
    80% {
      -webkit-box-shadow: 0 0 17px 3px #f00,0 0 4px 2px #f00;
              box-shadow: 0 0 17px 3px #f00,0 0 4px 2px #f00;
    }
    100% {
      -webkit-box-shadow: 0 0 17px 3px #C586C0,0 0 4px 2px #C586C0;
              box-shadow: 0 0 17px 3px #C586C0,0 0 4px 2px #C586C0;
    }
  }
  
  @-webkit-keyframes half-yellow-shadow {
    0% {
      top: 0;
      left: 0;
      height: 50%;
      width: 50%;
    }
    16.66% {
      top: 0;
      left: 0;
      height: 50%;
      width: 100%;
    }
    32.32% {
      top: 0;
      left: 50%;
      height: 50%;
      width: 50%;
    }
    49.98% {
      top: 50%;
      left: 50%;
      height: 50%;
      width: 50%;
    }
    66.64% {
      top: 50%;
      left: 0;
      height: 50%;
      width: 100%;
    }
    83.3% {
      top: 50%;
      left: 0;
      height: 50%;
      width: 50%;
    }
    100% {
      top: 0;
      left: 0;
      height: 50%;
      width: 50%;
    }
  }
  
  @keyframes half-yellow-shadow {
    0% {
      top: 0;
      left: 0;
      height: 50%;
      width: 50%;
    }
    16.66% {
      top: 0;
      left: 0;
      height: 50%;
      width: 100%;
    }
    32.32% {
      top: 0;
      left: 50%;
      height: 50%;
      width: 50%;
    }
    49.98% {
      top: 50%;
      left: 50%;
      height: 50%;
      width: 50%;
    }
    66.64% {
      top: 50%;
      left: 0;
      height: 50%;
      width: 100%;
    }
    83.3% {
      top: 50%;
      left: 0;
      height: 50%;
      width: 50%;
    }
    100% {
      top: 0;
      left: 0;
      height: 50%;
      width: 50%;
    }
  }
  
  @-webkit-keyframes half-cyan-shadow {
    0% {
      bottom: 0;
      right: 0;
      height: 50%;
      width: 50%;
    }
    16.66% {
      bottom: 0;
      right: 0;
      height: 50%;
      width: 100%;
    }
    32.32% {
      bottom: 0;
      right: 50%;
      height: 50%;
      width: 50%;
    }
    49.98% {
      bottom: 50%;
      right: 50%;
      height: 50%;
      width: 50%;
    }
    66.64% {
      bottom: 50%;
      right: 0;
      height: 50%;
      width: 100%;
    }
    83.3% {
      bottom: 50%;
      right: 0;
      height: 50%;
      width: 50%;
    }
    100% {
      bottom: 0;
      right: 0;
      height: 50%;
      width: 50%;
    }
  }
  
  @keyframes half-cyan-shadow {
    0% {
      bottom: 0;
      right: 0;
      height: 50%;
      width: 50%;
    }
    16.66% {
      bottom: 0;
      right: 0;
      height: 50%;
      width: 100%;
    }
    32.32% {
      bottom: 0;
      right: 50%;
      height: 50%;
      width: 50%;
    }
    49.98% {
      bottom: 50%;
      right: 50%;
      height: 50%;
      width: 50%;
    }
    66.64% {
      bottom: 50%;
      right: 0;
      height: 50%;
      width: 100%;
    }
    83.3% {
      bottom: 50%;
      right: 0;
      height: 50%;
      width: 50%;
    }
    100% {
      bottom: 0;
      right: 0;
      height: 50%;
      width: 50%;
    }
  }