.star {
    position: relative;

    background: radial-gradient(closest-side,rgb(122, 240, 220),
    rgb(140, 255, 236));
    
    height: 4px;
    width: 4px;

    animation-name: twinkle;
  
    animation-iteration-count: infinite;

    border-radius: 50%;
}

.star1 {

}

@keyframes twinkle {
    20% {
        transform: scale(0.5);
        opacity: 0.5;
        background: white;
        }
}

.star-containers{
    position: absolute;
    width: 50%;
    min-width: 375px;
    height: 600px;
    left: 50%;
    top: 10%;
    transform: translateX(-50%);
    z-index: 0;
}